import { RouteConfig } from 'vue-router';
import stores from '@/store/core/RootStore';

const AuthView = () => import('@/views/auth/auth.vue');

const AuthLoginView = () => import('@/views/auth/login/login.vue');
const AuthLoginIndex = () => import('@/views/auth/login/index.vue');

const AuthRegisterView = () => import('@/views/auth/register/register.vue');
const AuthRegisterIndex = () => import('@/views/auth/register/index.vue');

const AuthRecoveryView = () => import('@/views/auth/recovery/recovery.vue');
const AuthRecoveryIndex = () => import('@/views/auth/recovery/index.vue');

const AuthRecoveryConfirmationIndex = () =>
  import('@/views/auth/recovery/confirmation.vue');
export default {
  path: '/auth',
  component: AuthView,
  children: [
    {
      path: 'login',
      component: AuthLoginView,
      children: [
        {
          path: '',
          name: 'auth-login-index',
          meta: { layout: 'auth', public: true },
          component: AuthLoginIndex,
        },
      ],
    },
    {
      path: 'register',
      component: AuthRegisterView,
      children: [
        {
          path: '',
          name: 'auth-register-index',
          meta: { layout: 'auth', public: true },
          component: AuthRegisterIndex,
        },
      ],
    },
    {
      path: 'register/confirmation',
      component: AuthRegisterView,
      meta: { layout: 'auth', public: true },
      beforeEnter: async (to, from, next) => {
        console.log(to.query.code);

        try {
          if (to.query.code) {
            await stores.user.CONFIRMCODE(to.query.code as string);
            next({ path: '/' });
          }
        } catch (e) {
          console.warn(e);
        }
      },
    },
    {
      path: 'recovery',
      component: AuthRecoveryView,
      children: [
        {
          path: '',
          name: 'auth-recovery-index',
          meta: { layout: 'auth', public: true },
          component: AuthRecoveryIndex,
        },
        {
          path: 'confirmation',
          name: 'auth-recovery-index',
          meta: { layout: 'auth', public: true },
          component: AuthRecoveryConfirmationIndex,
        },
      ],
    },
  ],
} as RouteConfig;
