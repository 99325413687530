import { action, observable, computed } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import StoreConstructor from '@/store/core/StoreConstructor';
import services from '@/api/services';
import {
  ICashRegistratorsListResponse,
  ICashRegistratorsListRequest,
  IUnassignedRegistersItem,
  FiltersItem,
} from '@/api/services/cashRegisters/types';

export default class User extends StoreConstructor {
  @observable registrators: ICashRegistratorsListResponse['registrators'] = [];
  @observable fiscalNumbers: ICashRegistratorsListResponse['fiscalNumbers'] =
    [];
  @observable filtersItem: FiltersItem = {
    adress: '',
    kkmNumber: '',
    fiscalNumber: '',
  };

  constructor(stores: RootStores) {
    super(stores);
  }

  @computed get sortedRegistrators() {
    return this.registrators
      .filter((item) =>
        item.address
          ?.toLowerCase()
          .includes(this.filtersItem.adress.toLowerCase()),
      )
      .map((item) => {
        return {
          ...item,
          cashRegisters: item?.cashRegisters?.filter((fm) =>
            fm.fiscalMemoryNumber.includes(
              this.filtersItem.fiscalNumber.toLocaleLowerCase(),
            ),
          ),
        };
      })
      .map((item) => {
        return {
          ...item,
          cashRegisters: item?.cashRegisters?.filter((fm) =>
            fm.registrationNumber?.includes(
              this.filtersItem.kkmNumber.toLocaleLowerCase(),
            ),
          ),
        };
      });
  }

  @computed get sortedFiscalNumbers() {
    return this.fiscalNumbers.filter((item) =>
      item.number
        ?.toLowerCase()
        .includes(this.filtersItem.fiscalNumber.toLowerCase()),
    );
  }

  @action setRegistrators(val: ICashRegistratorsListResponse['registrators']) {
    this.registrators = val;
  }

  @action setFiscalNumbers(
    val: ICashRegistratorsListResponse['fiscalNumbers'],
  ) {
    this.fiscalNumbers = val;
  }

  @action.bound handleSearch(type: keyof FiltersItem, val: string) {
    this.filtersItem[type] = val;
  }

  @action.bound addFiscalNumbers(val: IUnassignedRegistersItem) {
    this.fiscalNumbers.push(val);
  }

  @action.bound async GETLIST(props: ICashRegistratorsListRequest) {
    try {
      const { data } = await services.cashRegisters.getList(props);
      if (data?.registrators) {
        this.setRegistrators(data.registrators);
      }
      if (data?.fiscalNumbers) {
        this.setFiscalNumbers(data.fiscalNumbers);
      }
    } catch (e: any) {
      console.warn(e);
    }
  }

  @action.bound RESERVE = async () => {
    try {
      const { data } = await services.cashRegisters.reserve();
      if (data) {
        this.addFiscalNumbers(data);
      }
    } catch (e: any) {
      console.warn(e);
    }
  };

  @action.bound STOP = async (fn: string) => {
    try {
      await services.cashRegisters.stop(fn);
      const deletedFNIndex = this.fiscalNumbers.findIndex(
        (item) => item.number === fn,
      );

      if (deletedFNIndex > -1) {
        this.fiscalNumbers.splice(deletedFNIndex, 1);
      }
    } catch (e: any) {
      console.warn(e);
    }
  };
}
