import { RouteConfig } from 'vue-router';

const CashRegistersView = () =>
  import('@/views/cashRegisters/cashRegisters.vue');
const CashRegistersIndex = () => import('@/views/cashRegisters/index.vue');

export default {
  path: '/cash-registers',
  component: CashRegistersView,
  meta: { layout: 'default', public: false },
  children: [
    {
      path: '',
      name: 'cash-registers-index',
      meta: { public: false },
      component: CashRegistersIndex,
    },
  ],
} as RouteConfig;
