import { RouteConfig } from 'vue-router';
import AuthRoutes from './auth';
import CashRegisters from './cashRegisters';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'cash-registers-index' },
  },
  AuthRoutes,
  CashRegisters,
];

export default routes;
