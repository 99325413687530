import { ApiService } from '@/api/services/core';
import { baseApi } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import {
  ICashRegistratorsListResponse,
  ICashRegistratorsListRequest,
} from '@/api/services/cashRegisters/types';

export class CashRegistersService extends ApiService {
  public getList(params: ICashRegistratorsListRequest) {
    return baseApi.get<ICashRegistratorsListResponse>(
      ENDPOINTS.places.cashRegisters,
      {
        params,
      },
    );
  }

  public reserve() {
    return baseApi.get(ENDPOINTS.fn.reserve);
  }

  public stop(fn: string) {
    return baseApi.delete(ENDPOINTS.fn.free(fn));
  }
}
