import { createUrl } from '@/utils';

const auth = createUrl('auth');
const reg = createUrl('registration');

const login = auth('login');
const refresh = auth('refresh');
const user = auth('me');
const registration = reg('');
const confirmCode = (code: string) => reg(`confirm?key=${code}`);

export const authEndpoints = {
  login,
  refresh,
  user,
  registration,
  confirmCode,
};
