export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

/**
 *
 * @param number Number
 * @param txt for example Russian: ['Рубль', 'Рубля', 'Рублей', 'Рубль'], English: ['Ruble', 'Rubles', 'Rubles', 'Rubles']
 * @param cases
 */
export const sklonenie = (
  number: number,
  txt: string[],
  cases = [2, 3, 1, 1, 1, 2],
) =>
  txt[
    number === 1
      ? 0
      : number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
