export type Credentials = {
  login: string;
  password: string;
};

export type AuthData = {
  accessToken: string;
  accessTokenTTL: string;
  refreshToken: string;
  refreshTokenTTL: string;
};

export type UserData = {
  login: string;
  email: string;
  name: string;
  vatPayer: boolean;
  fullName: string;
};

export enum LegalPersonType {
  Entity = 'ENTITY',
  Individual = 'INDIVIDUAL',
}

export interface ICompanyISNAKData {
  tin: string;
  companyName: string;
  type: LegalPersonType;
  legalAddress: string;
  taxAuthorityDepartment: number;
  activityCode: string;
  vatPayer?: boolean;
}

export interface IPersonCredentials {
  userName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  login: string;
  password: string;
  legalPerson: ICompanyISNAKData;
}
